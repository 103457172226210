import React, { useState } from "react";
import NavAndTopBar from "../../shared/layouts/NavAndTopBar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import PinterestIcon from "@mui/icons-material/Pinterest";
import TikTokIcon from "@mui/icons-material/Theaters";
import CloseIcon from "@mui/icons-material/Close";

export default function PostPublicationInfoForm() {
  const [isCreateMode, setIsCreateMode] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [status, setStatus] = useState("");
  const [showUpdateFields, setShowUpdateFields] = useState(false);

  const mediaOptions = [
    { name: "Instagram", color: "bg-pink-500", icon: <InstagramIcon /> },
    { name: "Facebook", color: "bg-blue-600", icon: <FacebookIcon /> },
    { name: "LinkedIn", color: "bg-blue-700", icon: <LinkedInIcon /> },
    { name: "X", color: "bg-blue-400", icon: <TwitterIcon /> },
    { name: "Pinterest", color: "bg-red-500", icon: <PinterestIcon /> },
    { name: "TikTok", color: "bg-black", icon: <TikTokIcon /> },
  ];

  const staticData = {
    date: "2025-01-08",
    media: ["Instagram", "Facebook"],
    status: "Posted",
  };

  const handleMediaChange = (name) => {
    setSelectedMedia((prev) =>
      prev.includes(name)
        ? prev.filter((media) => media !== name)
        : [...prev, name]
    );
  };

  const handleRemoveMedia = (name) => {
    setSelectedMedia((prev) => prev.filter((media) => media !== name));
  };

  const handleGetData = () => {
    if (selectedDate && selectedDate.format("YYYY-MM-DD") === staticData.date) {
      setSelectedMedia(staticData.media);
      setStatus(staticData.status);
      setShowUpdateFields(true);
    } else {
      alert("No data found for the selected date.");
      setShowUpdateFields(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      mode: isCreateMode ? "Create" : "Update",
      selectedDate,
      selectedMedia,
      status,
    });
  };

  return (
    <NavAndTopBar>
      <div className="max-w-2xl mx-auto p-6 bg-white shadow-md rounded-md m-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold">
            {isCreateMode ? "Store Post publication info" : "Update Post publication info"}
          </h2>
          <button
            className="bg-gray-200 text-gray-700 py-1 px-4 rounded-md hover:bg-gray-300 transition"
            onClick={() => {
              setIsCreateMode(!isCreateMode);
              setShowUpdateFields(false);
              setSelectedDate(null);
              setSelectedMedia([]);
              setStatus("");
            }}
          >
            {isCreateMode ? "Switch to Update" : "Switch to Create"}
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          {/* Date Picker */}
          <div className="flex gap-3 items-center content-center">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                // label="Select Date"
                value={selectedDate}
                onChange={(newValue) => setSelectedDate(newValue)}
                renderInput={(params) => (
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Date
                    </label>
                    <input
                      {...params.inputProps}
                      className="w-full p-2 border border-gray-300 rounded-md"
                    />
                  </div>
                )}
              />
            </LocalizationProvider>

            {/* Show "Get Data" button in Update mode */}
            {!isCreateMode && (
              <div className="">
                <button
                  type="button"
                  onClick={handleGetData}
                  className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition"
                >
                  Get Data
                </button>
              </div>
            )}
          </div>

          {/* Show fields only if in Create mode or data is fetched in Update mode */}
          {(isCreateMode || showUpdateFields) && (
            <>
              {/* Social Media Checkboxes */}
              <div className="my-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Select Media
                </label>
                <div className="flex flex-wrap gap-2">
                  {mediaOptions.map((media) => (
                    <label
                      key={media.name}
                      className={`flex items-center gap-2 px-4 py-2 rounded-md text-white cursor-pointer ${
                        selectedMedia.includes(media.name)
                          ? `${media.color} border-4 border-white`
                          : media.color
                      }`}
                      onClick={() => handleMediaChange(media.name)}
                    >
                      <input
                        type="checkbox"
                        checked={selectedMedia.includes(media.name)}
                        onChange={() => handleMediaChange(media.name)}
                        className="hidden"
                      />
                      {media.icon}
                      {media.name}
                    </label>
                  ))}
                </div>
              </div>

              {/* Selected Media Chips */}
              {selectedMedia.length > 0 && (
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Selected Media
                  </label>
                  <div className="flex flex-wrap gap-2">
                    {selectedMedia.map((mediaName) => {
                      const media = mediaOptions.find(
                        (m) => m.name === mediaName
                      );
                      return (
                        <div
                          key={media.name}
                          className={`flex items-center gap-2 px-3 py-1 rounded-full text-white ${media.color}`}
                        >
                          {media.icon}
                          <span>{media.name}</span>
                          <button
                            type="button"
                            onClick={() => handleRemoveMedia(media.name)}
                            className="flex items-center justify-center w-6 h-6 rounded-full bg-white text-black"
                          >
                            <CloseIcon fontSize="small" />
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {/* Status Dropdown */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Status
                </label>
                <select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                >
                  <option value="" disabled>
                    Select Status
                  </option>
                  <option value="Posted">Posted</option>
                  <option value="Cancel">Cancel</option>
                  <option value="Rejected">Rejected</option>
                </select>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition"
              >
                {showUpdateFields ? "Update" : "Submit"}
              </button>
            </>
          )}
        </form>
      </div>
    </NavAndTopBar>
  );
}
