import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AILeadService from "../../../data-access/services/aiLeadServices";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import DetailCallLeads from "./DetailsCallLeads";
import DetailsCallLeads from "./DetailsCallLeads";

export default function CallLeads() {
  const [leadData, setLeadData] = useState([]);
  const [leadDetailsData, setLeadDetailsData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [leadDetailsColumns, setLeadDetailsColumns] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openDetailsCallLeadsModal, setOpenDetailsCallLeadsModal] =
    useState(false);

  const [date,setDate] = useState('2024-09')
  const [cellValue,setCellValue] = useState('Missed call')

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: "80%",
    bgcolor: "background.paper",
    border: "2px solid #184332",
    boxShadow: 40,
    p: 4,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AILeadService.getCallLeads();
        const data = response?.data?.data || [];

        // Extract unique column names dynamically
        const uniqueColumns = Array.from(
          new Set(data.flatMap((row) => Object.keys(row)))
        );
        setColumns(uniqueColumns);

        console.log("getCallLeads DATA : ", response?.data?.data || []);
        setLeadData(data);
      } catch (err) {
        setError(err.message ?? "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  const handleDetailsCallLeadsModalClose = () => {
    setOpenDetailsCallLeadsModal(false);
  };
  const handleRowClick = (col, lead_type) => {
    console.log("Col Clicked : ",col, lead_type)
    setDate(col)
    setCellValue(lead_type)
    setOpenDetailsCallLeadsModal(true)
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="bg-[#E4FFF4]">
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column}
                  align="left"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  {column === "lead_type" ? "Call Leads" : column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {leadData.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((col, colIndex) => (
                  <TableCell
                    key={colIndex}
                    style={{
                      backgroundColor: colIndex === 0 ? "#e8f5e9" : "inherit",
                    }}
                    onClick={()=> handleRowClick(col, row?.lead_type)}
                  >
                    {row[col] || 0}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {/* Grand Total Row */}
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Grand Total</TableCell>
              {columns.slice(1).map((col, colIndex) => (
                <TableCell key={colIndex} style={{ fontWeight: "bold" }}>
                  {leadData.reduce((sum, row) => sum + (row[col] || 0), 0)}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDetailsCallLeadsModal}
        onClose={handleDetailsCallLeadsModalClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openDetailsCallLeadsModal}>
          <Box sx={style}>
            <DetailsCallLeads
              date={date}
              cellValue={cellValue}
              closeModal={handleDetailsCallLeadsModalClose}
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
