import React from 'react';
import Chart from 'react-apexcharts';

const PieChart = ({options, series, height = 350}) => {

  return (
    <div className="chart">
      <Chart
        options={options}
        series={series}
        type="pie"
        height={height}
      />
    </div>
  );
};

export default PieChart;
