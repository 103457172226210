// import React, { useEffect, useState } from "react";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import AILeadService from "../../../data-access/services/aiLeadServices";

// export default function GoogleMapRanking() {
//     const [leadData, setLeadData] = useState([]);
//     const [error, setError] = useState(null);
//     const [loading, setLoading] = useState(true);

//   const data = [
//     {
//       no: 1,
//       keyword: 'Dental Clinic in "xxx"',
//       june: "N/A",
//       july: 9,
//       august: 2,
//       september: 2,
//     },
//     {
//       no: 2,
//       keyword: 'Dental Care in "xxx"',
//       june: "N/A",
//       july: 11,
//       august: 7,
//       september: 3,
//     },
//     {
//       no: 3,
//       keyword: 'Best Dental Care in "xxx"',
//       june: "N/A",
//       july: 8,
//       august: 7,
//       september: 6,
//     },
//     {
//       no: 4,
//       keyword: 'Dental Clinic in "xxx"',
//       june: "N/A",
//       july: 8,
//       august: 2,
//       september: 2,
//     },
//     {
//       no: 5,
//       keyword: 'Dental clinic near "xxx"',
//       june: "N/A",
//       july: 15,
//       august: 2,
//       september: 2,
//     },
//   ];

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await AILeadService.getGoogleMapRankingReports();
//         setLeadData(response?.data?.data || []);
//         console.log(
//           "getGoogleMapRankingReports DATA : ",
//           response?.data?.data || []
//         );
//       } catch (err) {
//         setError(err.message ?? "An error occurred while fetching data.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <TableContainer component={Paper}>
//       <Table sx={{ minWidth: 650 }} aria-label="simple table">
//         <TableHead style={{ backgroundColor: "#E4FFF4" }}>
//           <TableRow>
//             <TableCell sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
//               No.
//             </TableCell>
//             <TableCell sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
//               Keywords
//             </TableCell>
//             <TableCell sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
//               June
//             </TableCell>
//             <TableCell sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
//               July
//             </TableCell>
//             <TableCell sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
//               August
//             </TableCell>
//             <TableCell sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
//               September
//             </TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {data.map((row) => (
//             <TableRow key={row.no}>
//               <TableCell>{row.no}</TableCell>
//               <TableCell>{row.keyword}</TableCell>
//               <TableCell>{row.june}</TableCell>
//               <TableCell>{row.july}</TableCell>
//               <TableCell>{row.august}</TableCell>
//               <TableCell>{row.september}</TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// }


import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  Paper,
  CircularProgress,
} from "@mui/material";
import AILeadService from "../../../data-access/services/aiLeadServices";

const GoogleMapRanking = () => {
  const [columns, setColumns] = useState([]);
  const [leadData, setLeadData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AILeadService.getGoogleMapRankingReports();

        const data = response?.data?.data || []; // Extract data array

        if (data.length > 0) {
          const dynamicColumns = Object.keys(data[0]); // Extract column names from data
          setColumns(dynamicColumns);
          setLeadData(data);
        } else {
          console.log("No data available.");
          setLeadData([]);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper>
      {loading ? (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <CircularProgress />
        </div>
      ) : error ? (
        <div style={{ textAlign: "center", color: "red", padding: "20px" }}>
          Error: {error}
        </div>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell key={index}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {leadData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              ) : (
                leadData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {columns.map((column, colIndex) => (
                        <TableCell key={colIndex}>
                          {row[column] !== null && row[column] !== undefined
                            ? row[column]
                            : "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={leadData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
    </Paper>
  );
};

export default GoogleMapRanking;
