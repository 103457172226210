import React from 'react'
import LeadOneDashboard from '../LeadOneDashboard'

export default function LeadDetailsDialog() {
  return (
    <div className="max-h-[90vh] overflow-y-scroll">
      <LeadOneDashboard />
    </div>
  )
}
