import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AILeadService from "../../../data-access/services/aiLeadServices";

export default function SocialMediaReportFacebook() {
  const [leadData, setLeadData] = useState([]);
  const [columns, setColumns] = useState(["Actions"]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AILeadService.getSocialMediaFacebookReports();
        const apiData = response?.data?.data || [];

        if (apiData.length > 0) {
          // Extract unique months from the response keys
          const monthKeys = Object.keys(apiData[0]).filter(
            (key) => key !== "insight_type"
          );
          setColumns(["Actions", ...monthKeys]);

          // Map API data into the required format
          const formattedData = apiData.map((item) => {
            const { insight_type, ...monthlyData } = item;
            return {
              Actions: insight_type,
              ...monthlyData,
            };
          });
          setLeadData(formattedData);
        } else {
          setLeadData([]);
        }
      } catch (err) {
        setError(err.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="Social Media Report Table">
        <TableHead className="bg-[#E4FFF4]">
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column}
                align="left"
                sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {leadData.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column) => (
                <TableCell
                  key={column}
                  style={{
                    backgroundColor: "white",
                  }}
                >
                  {row[column] !== null && row[column] !== undefined
                    ? row[column]
                    : 0}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
