import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function GMBReports() {
  const data = [
    {
      action: "Business interactions",
      june: 225,
      july: 326,
      august: 317,
      september: 301,
    },
    { action: "Calls", june: 60, july: 71, august: 105, september: 106 },
    { action: "Website clicks", june: 44, july: 71, august: 70, september: 72 },
    { action: "GMB Post", june: 4, july: 3, august: 7, september: 7 },
  ];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className="bg-[#E4FFF4]">
          <TableRow>
            <TableCell sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
              Actions
            </TableCell>
            <TableCell sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
              June
            </TableCell>
            <TableCell sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
              July
            </TableCell>
            <TableCell sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
              August
            </TableCell>
            <TableCell sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
              September
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.action}>
              <TableCell>{row.action}</TableCell>
              <TableCell>{row.june}</TableCell>
              <TableCell>{row.july}</TableCell>
              <TableCell>{row.august}</TableCell>
              <TableCell>{row.september}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
