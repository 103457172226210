import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import AILeadService from "../../../data-access/services/aiLeadServices";

export default function DetailsCallLeads({date, cellValue}) {
  const [leadData, setLeadData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response =
          await AILeadService.getCallLeadsDetailsByPhoneAndDate(date, cellValue);
        const data = response?.data?.data || [];
        setLeadData(response?.data?.data || []);
        const uniqueColumns = Array.from(
          new Set(data.flatMap((row) => Object.keys(row)))
        );
        setColumns(uniqueColumns);
        console.log(
          "getCallLeadsDetailsByPhoneAndDate DATA : ",
          response?.data?.data || []
        );
        console.log("uniqueColumns DATA : ", uniqueColumns);
      } catch (err) {
        setError(err.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="dynamic table">
        <TableHead className="bg-[#E4FFF4]">
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column}
                align="left"
                sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}
              >
                {column === "Call_Status" ? "Call Status" : column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {leadData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((col, colIndex) => (
                  <TableCell key={colIndex}>{row[col] || 0}</TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={leadData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}
