import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AILeadService from "../../../data-access/services/aiLeadServices";

export default function SocialMediaReportFacebook() {
  const [leadData, setLeadData] = useState([]);
  const [columns, setColumns] = useState(["Actions"]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AILeadService.getSocialMediaInstragramReports();
        const data = response?.data?.data || [];

        // Extract dynamic columns from the API data keys
        const dynamicColumns = data.length > 0 ? Object.keys(data[0]).filter(key => key !== "insight_type") : [];
        setColumns(["Actions", ...dynamicColumns]);

        // Transform data for table rendering
        const formattedData = data.map(item => ({
          Actions: item.insight_type,
          ...dynamicColumns.reduce((acc, key) => {
            acc[key] = item[key] ?? 0; // Default to 0 if null
            return acc;
          }, {}),
        }));
        setLeadData(formattedData);
      } catch (err) {
        setError(err.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="Social Media Report Table">
        <TableHead className="bg-[#E4FFF4]">
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column}
                align="left"
                sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {leadData.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column) => (
                <TableCell
                  key={column}
                  style={{
                    backgroundColor: "white",
                  }}
                >
                  {row[column] || 0}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
