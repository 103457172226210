import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AILeadService from "../../../data-access/services/aiLeadServices";

export default function InfoLeads() {
  const [leadData, setLeadData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AILeadService.getInfoLeads();
        const data = response?.data?.data || [];

        // Dynamically extract unique columns from the API response
        const uniqueColumns = Array.from(
          new Set(data.flatMap((row) => Object.keys(row)))
        );
        setColumns(uniqueColumns);

        setLeadData(data);
      } catch (err) {
        setError(err.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="dynamic table">
        <TableHead className="bg-[#E4FFF4]">
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column}
                align="left"
                sx={{ whiteSpace: "nowrap", fontWeight: "bold" }}
              >
                {column === "Appointment Booked" ? "Info Leads" : column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {leadData.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((col, colIndex) => (
                <TableCell key={colIndex}>{row[col] || 0}</TableCell>
              ))}
            </TableRow>
          ))}
          {/* Grand Total Row */}
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>Grand Total</TableCell>
            {columns.slice(1).map((col, colIndex) => (
              <TableCell key={colIndex} style={{ fontWeight: "bold" }}>
                {leadData.reduce((sum, row) => sum + (row[col] || 0), 0)}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
