import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function LeadOneDashboard() {
  const columns = [
    { name: "Call Status", width: "10%" },
    { name: "Company Name", width: "15%" },
    { name: "Company ID", width: "10%" },
    { name: "Number Name", width: "10%" },
    { name: "Tracking Number", width: "15%" },
    { name: "Source", width: "10%" },
    { name: "Start Time", width: "10%" },
    { name: "Duration (seconds)", width: "10%" },
    { name: "Name", width: "10%" },
    { name: "Phone Number", width: "15%" },
    { name: "Email", width: "20%" },
    { name: "First-Time Caller", width: "10%" },
    { name: "City", width: "10%" },
    { name: "State", width: "10%" },
    { name: "Country", width: "10%" },
    { name: "Agent Name", width: "10%" },
    { name: "Agent Number", width: "10%" },
    { name: "Device Type", width: "10%" },
    { name: "Keywords", width: "15%" },
    { name: "Referrer", width: "10%" },
    { name: "Medium", width: "10%" },
    { name: "Landing Page", width: "15%" },
    { name: "Campaign", width: "10%" },
    { name: "Value", width: "10%" },
    { name: "Tags", width: "10%" },
    { name: "Qualified", width: "10%" },
    { name: "Recording URL", width: "15%" },
    { name: "Missed Call/Missed Opportunity", width: "15%" },
  ];

  const data = [
    {
      callStatus: "Completed",
      companyName: "TechCorp",
      companyId: "1234",
      numberName: "Support",
      trackingNumber: "800-555-1234",
      source: "Google Ads",
      startTime: "2024-08-09T10:00",
      duration: 120,
      name: "John Doe",
      phoneNumber: "555-555-5555",
      email: "johndoe@example.com",
      firstTimeCaller: true,
      city: "New York",
      state: "NY",
      country: "USA",
      agentName: "Jane Smith",
      agentNumber: "9876",
      deviceType: "Mobile",
      keywords: "Tech support",
      referrer: "Google",
      medium: "CPC",
      landingPage: "/support",
      campaign: "Summer2024",
      value: 500,
      tags: "high-value",
      qualified: true,
      recordingUrl: "http://example.com/recording/1",
      missedCall: false,
    },
    // Add 9 more dummy entries below
    {
      callStatus: "Missed",
      companyName: "InnoSoft",
      companyId: "5678",
      numberName: "Sales",
      trackingNumber: "800-555-5678",
      source: "Facebook",
      startTime: "2024-08-09T11:00",
      duration: 0,
      name: "Alice Johnson",
      phoneNumber: "555-555-1234",
      email: "alicejohnson@example.com",
      firstTimeCaller: false,
      city: "Los Angeles",
      state: "CA",
      country: "USA",
      agentName: "Bob Williams",
      agentNumber: "5432",
      deviceType: "Desktop",
      keywords: "Buy software",
      referrer: "Facebook",
      medium: "Social",
      landingPage: "/pricing",
      campaign: "Promo2024",
      value: 0,
      tags: "missed",
      qualified: false,
      recordingUrl: "http://example.com/recording/2",
      missedCall: true,
    },
    // Add more data similar to the above
  ];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className="bg-[#E4FFF4]">
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.name}
                align="left"
                sx={{ width: column.width, whiteSpace: "nowrap" }}
              >
                {column.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.callStatus}</TableCell>
              <TableCell>{row.companyName}</TableCell>
              <TableCell>{row.companyId}</TableCell>
              <TableCell>{row.numberName}</TableCell>
              <TableCell>{row.trackingNumber}</TableCell>
              <TableCell>{row.source}</TableCell>
              <TableCell>{row.startTime}</TableCell>
              <TableCell>{row.duration}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.phoneNumber}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.firstTimeCaller ? "Yes" : "No"}</TableCell>
              <TableCell>{row.city}</TableCell>
              <TableCell>{row.state}</TableCell>
              <TableCell>{row.country}</TableCell>
              <TableCell>{row.agentName}</TableCell>
              <TableCell>{row.agentNumber}</TableCell>
              <TableCell>{row.deviceType}</TableCell>
              <TableCell>{row.keywords}</TableCell>
              <TableCell>{row.referrer}</TableCell>
              <TableCell>{row.medium}</TableCell>
              <TableCell>{row.landingPage}</TableCell>
              <TableCell>{row.campaign}</TableCell>
              <TableCell>{row.value}</TableCell>
              <TableCell>{row.tags}</TableCell>
              <TableCell>{row.qualified ? "Yes" : "No"}</TableCell>
              <TableCell>
                <a
                  href={row.recordingUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Recording
                </a>
              </TableCell>
              <TableCell>{row.missedCall ? "Yes" : "No"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
