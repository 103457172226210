import React from 'react';
import Chart from 'react-apexcharts';

const LineChartWithMarkers = () => {
  const options = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      // categories: [
      //   'January', 'February', 'March', 'April', 'May', 'June',
      //   'July', 'August', 'September', 'October', 'November', 'December',
      // ],
      categories: [
        '01-08-2024', '02-08-2024', '03-08-2024', '04-08-2024', '05-08-2024', '06-08-2024',
        '07-08-2024', '8-08-2024', '09-08-2024', '10-08-2024', '11-08-2024', '12-08-2024', '13-08-2024', '14-08-2024'
      ],
    },
    markers: {
      size: 5,
      colors: ['#FF4560', '#775DD0', '#00E396'],
      strokeColors: '#fff',
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },
    stroke: {
      curve: 'smooth',
    },
    legend: {
      position: 'top',
    },
  };

  const series = [
    {
      name: 'My Business',
      data: [30, 40, 35, 50, 49, 60, 70, 91, 150, 140, 130, 160, 80, 150],
    },
    {
      name: 'Website',
      data: [40, 30, 25, 40, 39, 20, 60, 81, 75, 30, 120, 110, 120, 160],
    },
    {
      name: 'Social Media',
      data: [10, 30, 15, 40, 29, 80, 100, 80, 95, 110, 100, 90, 150, 40],
    },
  ];

  return (
    <div className="chart">
      <Chart
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default LineChartWithMarkers;
