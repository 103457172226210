import React, { useState } from "react";
import NavAndTopBar from "../../shared/layouts/NavAndTopBar";
import CallLeads from "./CallLeads";
import InfoLeads from "./InfoLeads";
import GoogleRanking from "./GoogleRanking";
import SocialMediaReportFacebook from "./SocialMediaReportFacebook";
import SocialMediaReportInstagram from "./SocialMediaReportInstagram";
import SocialMediaPostingCalendar from "./SocialMediaPostingCalendar";
import GoogleMapRanking from "./GoogleMapRanking";
import GMBReports from "./GMBReport";

export default function LeadReport() {
  return (
    <NavAndTopBar>
      <div className="bg-white p-4 flex flex-col gap-8">
        {/* {leadType === "leadOne" && <CallLeads />} */}
        {/* {leadType === "leadTwo" && <InfoLeads />} */}
        <div>
          <div className="flex justify-center">
            <p className="text-[#0d271e] font-bold text-2xl my-2">
              AI Lead Report
            </p>
          </div>
          <div className="flex flex-col gap-8">
            <CallLeads />
            <InfoLeads />
          </div>
        </div>
        <div>
          <div className="flex justify-center">
            <p className="text-[#0d271e] font-bold text-2xl my-2">
              Website Ranking Report
            </p>
          </div>
          <p className="text-[#0d271e] font-bold text-lg my-2">
            Google Ranking
          </p>
          <GoogleRanking />
          <p className="text-[#0d271e] font-bold text-lg my-2">
            Google Map Ranking
          </p>
          <GoogleMapRanking />
          <p className="text-[#0d271e] font-bold text-lg my-2">
            GMB Report
          </p>
          <GMBReports />
        </div>
        <div>
          <div className="flex justify-center">
            <p className="text-[#0d271e] font-bold text-2xl my-2">
              Social Media Report
            </p>
          </div>
          <div>
            <p className="text-[#0d271e] font-bold text-lg my-2">FaceBook</p>
            <SocialMediaReportFacebook />
            <p className="text-[#0d271e] font-bold text-lg my-2 mt-8">Instragram</p>
            <SocialMediaReportInstagram />
          </div>
        </div>
        <div>
          <div className="flex justify-center">
            <p className="text-[#0d271e] font-bold text-2xl my-2">
              Social Media Posting Calendar
            </p>
          </div>
          <SocialMediaPostingCalendar />
        </div>
      </div>
    </NavAndTopBar>
  );
}
