import React, { useContext, useState } from "react";
import {
  Backdrop,
  Box,
  ClickAwayListener,
  Fade,
  Modal,
  styled,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { useNavigate } from "react-router-dom";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { AuthContext } from "../../../contexts/AuthContext";
import LineChartWithMarkers from "./LineChartWithMarkers";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PieChart from "./PieChart";
import LeadDetailsDialog from "./modals/LeadDetailsDialog";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export default function LeadTwoDashboard() {
  const { fromDate, setFromDate, toDate, setToDate } = useContext(AuthContext);
  const [fromDateOpen, setFromDateOpen] = useState(false);
  const [toDateOpen, setToDateOpen] = useState(false);
  const [fromDateError, setFromDateError] = useState(false);
  const [toDateError, setToDateError] = useState(false);
  const [openLeadDetailsModal, setOpenLeadDetailsModal] = useState(false);

  const handleClickAway = () => {
    setFromDateOpen(false);
    setToDateOpen(false);
  };

  const hanldeLeadDetailsModalClose = () => {
    setOpenLeadDetailsModal(false);
  };

  const handleFromDateChange = (newFromDate) => {
    if (toDate && newFromDate.isAfter(toDate)) {
      setFromDateError(true);
    } else {
      setFromDateError(false);
      setFromDate(newFromDate);
    }
  };

  const handleToDateChange = (newToDate) => {
    if (fromDate && newToDate.isBefore(fromDate)) {
      setToDateError(true);
    } else {
      setToDateError(false);
      setToDate(newToDate);
    }
  };

  const disableFromDate = (date) => {
    return toDate ? date.isAfter(toDate, "day") : false;
  };

  const disableToDate = (date) => {
    return fromDate ? date.isBefore(fromDate, "day") : false;
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 14,
      padding: "10px",
    },
  }));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: "80%",
    bgcolor: "background.paper",
    border: "2px solid #184332",
    boxShadow: 40,
    p: 4,
  };

  const LeadsOptions = {
    labels: ['Closed Leads', 'New Patients'],
    colors: ['#FF4560', '#00E396'],
    legend: {
      position: 'bottom',
    },
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          // Log the label of the clicked section
          const selectedLabel = LeadsOptions.labels[config.dataPointIndex];
          console.log(selectedLabel);
          setOpenLeadDetailsModal(true)
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val.toFixed(1)}%`,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '50%',
        },
        expandOnClick: true,
        dataLabels: {
          offset: 0,
        },
      },
    },
  };

  const LeadsSeries = [35, 65];

  const newLeadsDiffPlatformOptions = {
    labels: ['Social Media', 'Website', 'My Business'],
    colors: ['#008FFB', '#00E396', '#FEB019'],
    legend: {
      position: 'bottom',
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(1) + '%';
      },
    },
  };

  const newLeadsDiffPlatformOSeries = [45, 30, 25];

  const missedLeadsDiffPlatformOptions = {
    labels: ['Social Media', 'Website', 'My Business'],
    colors: ['#008FFB', '#00E396', '#FEB019'],
    legend: {
      position: 'bottom',
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(1) + '%';
      },
    },
  };

  const missedLeadsDiffPlatformOSeries = [28, 37, 35];

  return (
    <div>
      <div className="flex justify-center gap-3">
        <div className="flex flex-col justify-center items-center relative">
          <div className="flex items-center gap-2">
            <div>From</div>
            <div
              className="flex gap-4 justify-center items-center cursor-pointer text-[#184332] rounded-full border-[1px] border-[#969696] h-[40px] w-[157px] bg-white"
              onClick={() => {
                setFromDateOpen(!fromDateOpen);
                setToDateOpen(false);
              }}
            >
              <div>{dayjs(fromDate).format("MMM, YYYY")}</div>
              <CalendarMonthOutlinedIcon />
            </div>
          </div>
          {fromDateOpen && (
            <ClickAwayListener onClickAway={handleClickAway}>
              <div className="bg-white shadow-lg rounded-2xl absolute top-12 right-0 z-20">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DateCalendar"]}>
                    <DateCalendar
                      value={fromDate}
                      onChange={(newValue) => {
                        // setFromDate(newValue);
                        handleFromDateChange(newValue);
                        // setFromDateOpen(!fromDateOpen);
                      }}
                      onMonthChange={() => setFromDateOpen(!fromDateOpen)}
                      views={["year", "month"]}
                      shouldDisableDate={disableFromDate}
                      disableFuture
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </ClickAwayListener>
            // <DateCalendar value={value} onChange={(newValue) => {setValue(newValue); setToDateOpen(!toDateOpen)}} />
          )}
        </div>
        <div className="flex flex-col justify-center items-center relative">
          <div className="flex items-center gap-2">
            <div>To</div>
            <div
              className="flex gap-4 justify-center items-center cursor-pointer text-[#184332] rounded-full border-[1px] border-[#969696] h-[40px] w-[157px] bg-white"
              onClick={() => {
                setToDateOpen(!toDateOpen);
                setFromDateOpen(false);
              }}
            >
              <div>{dayjs(toDate).format("MMM, YYYY")}</div>
              <CalendarMonthOutlinedIcon />
            </div>
          </div>
          {toDateOpen && (
            <ClickAwayListener onClickAway={handleClickAway}>
              <div className="bg-white shadow-lg rounded-2xl absolute top-12 right-0 z-20">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DateCalendar"]}>
                    <DateCalendar
                      value={toDate}
                      onChange={(newValue) => {
                        // setToDate(dayjs(newValue).endOf("month"));
                        handleToDateChange(newValue);
                        // setToDateOpen(!toDateOpen);
                        console.log("newValue", newValue);
                      }}
                      onMonthChange={() => setToDateOpen(!toDateOpen)}
                      views={["month", "year"]}
                      shouldDisableDate={disableToDate}
                      disableFuture
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </ClickAwayListener>
            // <DateCalendar value={value} onChange={(newValue) => {setValue(newValue); setToDateOpen(!toDateOpen)}} />
          )}
        </div>
      </div>
      <div className="shadow-lg bg-white w-full rounded-[24px] px-4">
        <div className="pt-3 px-4">
          <div className="flex items-center gap-2">
            <p className="chart-subtitle">Compared Data Chart</p>
            <LightTooltip
              title={
                <div>
                  <p>
                    Here you can see the current and the previous months
                    compared data.
                  </p>
                  <p>This will provide you the graphical view of the data.</p>
                </div>
              }
              arrow
              placement="top"
            >
              <InfoOutlinedIcon sx={{ color: "#BDBDBD", fontSize: "20px" }} />
            </LightTooltip>
          </div>
        </div>
        <div className="w-full">
          <LineChartWithMarkers />
        </div>
      </div>
      <div className="flex justify-evenly py-4">
        <div className="shadow-lg bg-white rounded-[24px] px-4">
          <div className="pt-3 px-4">
            <div className="flex items-center gap-2">
              <p className="chart-subtitle">New Patient vs Opportunity</p>
              <LightTooltip
                title={
                  <div>
                    <p>
                      Here you can see the perchantage of the new and missed
                      leads/oppurtinities
                    </p>
                    <p>This will provide you the graphical view of the data.</p>
                  </div>
                }
                arrow
                placement="top"
              >
                <InfoOutlinedIcon sx={{ color: "#BDBDBD", fontSize: "20px" }} />
              </LightTooltip>
            </div>
          </div>
          <div className="py-2">
            <PieChart options={LeadsOptions} series={LeadsSeries} height={350}/>
          </div>
        </div>
        <div className="shadow-lg bg-white rounded-[24px] px-4">
          <div className="pt-3 px-4">
            <div className="flex items-center gap-2">
              <p className="chart-subtitle">New Leads Type</p>
              <LightTooltip
                title={
                  <div>
                    <p>
                      Here you can see the perchantage of the new leads from different platform.
                    </p>
                    <p>This will provide you the graphical view of the data.</p>
                  </div>
                }
                arrow
                placement="top"
              >
                <InfoOutlinedIcon sx={{ color: "#BDBDBD", fontSize: "20px" }} />
              </LightTooltip>
            </div>
          </div>
          <div className="py-2">
            <PieChart options={newLeadsDiffPlatformOptions} series={newLeadsDiffPlatformOSeries} height={350}/>
          </div>
        </div>
        <div className="shadow-lg bg-white rounded-[24px] px-4">
          <div className="pt-3 px-4">
            <div className="flex items-center gap-2">
              <p className="chart-subtitle">Missed Leads Type</p>
              <LightTooltip
                title={
                  <div>
                    <p>
                      Here you can see the perchantage of the new leads from different platform.
                    </p>
                    <p>This will provide you the graphical view of the data.</p>
                  </div>
                }
                arrow
                placement="top"
              >
                <InfoOutlinedIcon sx={{ color: "#BDBDBD", fontSize: "20px" }} />
              </LightTooltip>
            </div>
          </div>
          <div className="py-2">
            <PieChart options={missedLeadsDiffPlatformOptions} series={missedLeadsDiffPlatformOSeries} height={350}/>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openLeadDetailsModal}
        onClose={hanldeLeadDetailsModalClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openLeadDetailsModal}>
          <Box sx={style}>
            <LeadDetailsDialog
              // trendChartData={trendChartMultipleCategoryModalData}
              // closeModal={handleTrendChartModalClose}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
