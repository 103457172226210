import React, { useState } from "react";
import NavAndTopBar from "../../shared/layouts/NavAndTopBar";
import LeadOneDashboard from "./LeadOneDashboard";
import LeadTwoDashboard from "./LeadTwoDashboard";

export default function AIRegeneration() {
  const [leadType, setLeadType] = useState("leadOne");
  return (
    <NavAndTopBar>
      <div className="bg-white px-4">
        <div className="flex justify-between items-center bg-white shadow-lg rounded-xl px-1 w-[200px] h-[50px]">
          <div
            className={`flex items-center justify-center w-[100px] h-[50px] ${
              leadType === "leadOne" ? "bg-[#184332]" : "bg-white"
            } rounded-[10px] cursor-pointer`}
            onClick={() => setLeadType("leadOne")}
          >
            <p
              className={`${
                leadType === "leadOne"
                  ? "button-linear-text"
                  : "button-dashboard-tab-text"
              }`}
              style={{ textTransform: "capitalize" }}
            >
              Lead One
            </p>
          </div>
          <div
            className={`flex items-center justify-center w-[100px] h-[50px] ${
              leadType !== "leadOne" ? "bg-[#184332]" : "bg-white"
            } rounded-[10px] cursor-pointer`}
            onClick={() => setLeadType("leadTwo")}
          >
            <p
              className={`${
                leadType !== "leadOne"
                  ? "button-linear-text"
                  : "button-dashboard-tab-text"
              }`}
              style={{ textTransform: "capitalize" }}
            >
              Lead Two
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white p-4">
        {leadType === "leadOne" && <LeadOneDashboard />}
        {leadType === "leadTwo" && <LeadTwoDashboard />}
      </div>
    </NavAndTopBar>
  );
}
