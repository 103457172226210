import React from "react";
import axios from "axios";
import { EnvVariable, SecretKey } from "../../../utils/envVariables";
import dayjs from "dayjs";

class AILeadService {
  getCallLeads = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/ai/lead-report/call-leads`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          // reject(error.response.data.error);
          reject("somethingWentWrong");
        });
    });
  };
  getCallLeadsDetailsByPhoneAndDate = (
    date,
    cellValue
  ) => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/ai/lead-report/detail-call-leads?date=${date}&cellValue=${cellValue}`;
      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          // reject(error.response.data.error);
          reject("somethingWentWrong");
        });
    });
  };
  getInfoLeads = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/ai/lead-report/info-leads`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };
  getGoogleSearchRankings = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/ai/lead-report/google-search-rankings`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };
  getGoogleMapRankingReports = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/ai/lead-report/google-map-rankings`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };
  getGMBReports = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/ai/lead-report/gmb-report`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };
  getSocialMediaFacebookReports = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/ai/lead-report/social-media-facebook-report`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };
  getSocialMediaInstragramReports = () => {
    return new Promise((resolve, reject) => {
      const URL = `${EnvVariable.BASEURL}/ai/lead-report/social-media-instragram-report`;

      return axios
        .get(URL)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            resolve(response);
          } else reject("somethingWentWrong");
        })
        .catch((error) => {
          reject(error.response.data.error);
        });
    });
  };
}

const instance = new AILeadService();
export default instance;
